<template>
  <div v-loading.fullscreen="loading" class="flex flex-col responsive-page fixed-table">
    <div class="flex items-center pb-8 border-solid border-b-1 border-gray-300 header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader :title="$t('location.location_list')" @load:refreshList="fetchList" />
      </div>
      <div class="flex header-container__right">
        <el-button class="mr-8" type="primary" icon="el-icon-plus" size="medium" @click="onCreateItem()">
          {{ $t('location.new_location') }}
        </el-button>

        <el-input
          class="w-60"
          prefix-icon="el-icon-search"
          v-model="dataSearch.text"
          @keyup="refreshItemList"
          @blur="updateTextSearch"
        />
      </div>
    </div>

    <div v-if="displayItems.length > 0" class="flex-grow flex flex-col">
      <div class="flex justify-end table-actions py-5">
        <el-checkbox v-model="isShowOnlyActive">{{ $t('carrier.show_only_active') }}</el-checkbox>
      </div>
      <div class="mb-16 flex-grow table-container">
        <el-table
          :data="displayItems"
          class="table-content rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @row-click="onEditItem"
        >
          <el-table-column prop="name" :label="$t('location.name')" />
          <el-table-column prop="totalProject" :label="$t('location.project_amount')" width="150" />
          <el-table-column prop="isActive" :label="$t('status')" width="150">
            <template v-slot="scope">
              {{ scope.row.isActive ? $t('active') : $t('inactive') }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="justify-center flex flex-row mb-16 paging-container">
        <el-pagination
          layout="prev, pager, next"
          :total="totalPaging"
          :page-size="itemsPerPage"
          :current-page="currentPageNum"
          @current-change="currentPageChange($event)"
          background
          class="custom-pagination"
        />
      </div>
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
  </div>
</template>

<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { IS_ACTIVE_LOCATION, LOAD_LOCATION_LIST } from '@/store/actions'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { ICommonSearch, ILocation, IPaginationPayload } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
@Options({
  components: { ItemNotFound, PageHeader },
  name: ESmartBarcodeMenu.LOCATION,
})
export default class Location extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  loading = false
  isShowOnlyActive = true

  get getItems(): ILocation[] {
    const locations = [...this.$store.state.location.locations].filter((val: ILocation) => {
      if (val) return val
    })
    return locations
  }

  get totalItems(): number {
    return this.$store.state.location.totalLocation
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    let displayItems = this.getItems.filter((location: ILocation) =>
      this.cleanString(location.name || '').includes(cleanSearchText)
    )

    if (this.isShowOnlyActive) {
      displayItems = displayItems.filter((item: ILocation) => item.isActive)
    }

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()

    this.totalPaging = this.totalItems
  }

  refreshItemList() {
    this.debounceFilter()
  }

  @Watch('isShowOnlyActive')
  watchIsShownOnlyActive() {
    this.currentPageNum = 1
    this.$store.commit(IS_ACTIVE_LOCATION, this.isShowOnlyActive)
    this.filterItems()
  }

  onCreateItem() {
    this.$router.push({ name: 'locationCreate' })
  }

  onEditItem(location: ILocation) {
    this.$router.push({
      name: 'locationEdit',
      params: { id: location.id },
    })
  }

  get urlPageNumParam() {
    return this.$route.query.page
  }

  @Watch('currentPageNum')
  async fetchList(page: number) {
    this.loading = true
    const curPageNum = page ?? this.currentPageNum

    const paginationParams: IPaginationPayload = {
      skip: (curPageNum - 1) * this.itemsPerPage,
      count: this.itemsPerPage,
    }
    await this.$store.dispatch(LOAD_LOCATION_LIST, { ...paginationParams }).finally(() => (this.loading = false))
    this.filterItems()
  }

  async created() {
    this.routeName = 'location'
    this.isShowOnlyActive = this.$store.state.location.isShowOnlyActive
    this.getQueryData()
    if (!this.$store.state.location.isLoaded) await this.fetchList(this.urlPageNumParam)
    else this.filterItems()

    this.debounceFilter = debounce(() => this.filterItems(), FILTER_DEBOUNCE_TIME)
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.header-container-wrapper {
  background-color: $body-bg;
}

.fixed-table:deep() {
  height: calc(100vh - 100px);
  min-width: 700px;

  &.table-content {
    overflow: auto;
    height: calc(100vh - 400px);
    min-height: 200px;
  }
  &.el-table {
    &::before {
      height: 0px;
    }
    &__header-wrapper {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 10;
    }
  }
}
</style>
