
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { IS_ACTIVE_LOCATION, LOAD_LOCATION_LIST } from '@/store/actions'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { ICommonSearch, ILocation, IPaginationPayload } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
@Options({
  components: { ItemNotFound, PageHeader },
  name: ESmartBarcodeMenu.LOCATION,
})
export default class Location extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  loading = false
  isShowOnlyActive = true

  get getItems(): ILocation[] {
    const locations = [...this.$store.state.location.locations].filter((val: ILocation) => {
      if (val) return val
    })
    return locations
  }

  get totalItems(): number {
    return this.$store.state.location.totalLocation
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    let displayItems = this.getItems.filter((location: ILocation) =>
      this.cleanString(location.name || '').includes(cleanSearchText)
    )

    if (this.isShowOnlyActive) {
      displayItems = displayItems.filter((item: ILocation) => item.isActive)
    }

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()

    this.totalPaging = this.totalItems
  }

  refreshItemList() {
    this.debounceFilter()
  }

  @Watch('isShowOnlyActive')
  watchIsShownOnlyActive() {
    this.currentPageNum = 1
    this.$store.commit(IS_ACTIVE_LOCATION, this.isShowOnlyActive)
    this.filterItems()
  }

  onCreateItem() {
    this.$router.push({ name: 'locationCreate' })
  }

  onEditItem(location: ILocation) {
    this.$router.push({
      name: 'locationEdit',
      params: { id: location.id },
    })
  }

  get urlPageNumParam() {
    return this.$route.query.page
  }

  @Watch('currentPageNum')
  async fetchList(page: number) {
    this.loading = true
    const curPageNum = page ?? this.currentPageNum

    const paginationParams: IPaginationPayload = {
      skip: (curPageNum - 1) * this.itemsPerPage,
      count: this.itemsPerPage,
    }
    await this.$store.dispatch(LOAD_LOCATION_LIST, { ...paginationParams }).finally(() => (this.loading = false))
    this.filterItems()
  }

  async created() {
    this.routeName = 'location'
    this.isShowOnlyActive = this.$store.state.location.isShowOnlyActive
    this.getQueryData()
    if (!this.$store.state.location.isLoaded) await this.fetchList(this.urlPageNumParam)
    else this.filterItems()

    this.debounceFilter = debounce(() => this.filterItems(), FILTER_DEBOUNCE_TIME)
  }
}
